export default {
    kb: {
        name: "kinder beat!<span id='reg'>&reg;</span>",
        abrv: "KB",
        previews: [
            {
                coverFile: "kinder_beat_1_music_box_magic_cover.jpg",
                title: "Level 1: Music Box Magic",
                tracks: [
                    {
                        name: "Music Time",
                        file: "KB/Music_Time",
                        pdfFile: "KB/Kinder_Beat_1_Ex1_Music_Time"
                    },
                    {
                        name: "Kinder Bear Walks",
                        file: "KB/Kinder_Bear_Walks",
                        pdfFile: "KB/Kinder_Beat_1_Ex2_Kinder_Bear_Walks"
                    },
                    {
                        name: "Waves Roll In",
                        file: "KB/Waves_Roll_In",
                        pdfFile: "KB/Kinder_Beat_1_Ex3_Waves_Roll_In"
                    }
                ]
            }
        ]
    },
    kbpp: {
        name: "kinder beat!<span id='reg'>&reg;</span> piano",
        abrv: "KBPP",
        previews: [
            {
                title: "Level 2: Percussion Play",
                coverFile: "kinder_beat_2_percussion_play_cover.jpg",
                tracks: [
                    {
                        name: "Kinder Roo and Joey",
                        file: "KB/Kinder_Roo_Joey",
                        pdfFile: "KB/Kinder_Beat_2_Ex1_Kinder_Roo_and_Joey"
                    },
                    {
                        name: "Surf's Up",
                        file: "KB/Surfs_Up",
                        pdfFile: "KB/Kinder_Beat_2_Ex2_Surf's_Up"
                    },
                    {
                        name: "It's A Whale",
                        file: "KB/Its_A_Whale",
                        pdfFile: "KB/Kinder_Beat_2_Ex3_It's_a_Whale"
                    }
                ]
            },
        ]
    },
    kbp: {
        name: "kinder beat!<span id='reg'>&reg;</span> piano",
        abrv: "KBP",
        previews: [
            {
                title: "Level 3: Piano Prep",
                coverFile: "kinder_beat_3_piano_prep_cover.jpg",
                tracks: [
                    {
                        name: "Build It",
                        file: "KB/Build_It",
                        pdfFile: "KB/Kinder_Beat_3_Ex1_Build_It"
                    },
                    {
                        name: "Bravest Little Tug",
                        file: "KB/Bravest_Tug",
                        pdfFile: "KB/Kinder_Beat_3_Ex2_Bravest_Little_Tug"
                    },
                    {
                        name: "Tubby Sub",
                        file: "KB/Tubby_Sub",
                        pdfFile: "KB/Kinder_Beat_3_Ex3_Tubby_Sub"
                    }
                ]
            }
        ]
    },
    jr: {
        name: "junior piano",
        abrv: "JR",
        previews: [
            {
                coverFile: "junior_piano_1_cover.jpg",
                title: "Book 1",
                tracks: [
                    {
                        name: "Boogie Up",
                        file: "JR/Boogie_Up",
                        pdfFile: "JR/Junior_Piano_1_Ex1_Boogie_Up"
                    },
                    {
                        name: "Shake It",
                        file: "JR/Shake_It",
                        pdfFile: "JR/Junior_Piano_1_Ex2_Shake_It"
                    },
                    {
                        name: "Jam With Jazz",
                        file: "JR/Jam_With_Jazz",
                        pdfFile: "JR/Junior_Piano_1_Ex3_Jam_With_Jazz"
                    }
                ]
            },
            {
                coverFile: "junior_piano_2_cover.jpg",
                title: "Book 2",
                tracks: [
                    {
                        name: "Slam Dunk",
                        file: "JR/Slam_Dunk",
                        pdfFile: "JR/Junior_Piano_2_Ex1_Slam_Dunk"
                    },
                    {
                        name: "Bean Stalk Rag",
                        file: "JR/Bean_Stalk_Rag",
                        pdfFile: "JR/Junior_Piano_2_Ex2_Bean_Stalk_Rag"
                    },
                    {
                        name: "Mighty Aussie Moz",
                        file: "JR/Mighty_Aussie_Moz",
                        pdfFile: "JR/Junior_Piano_2_Ex3_Mighty_Aussie_Moz"
                    }
                ]
            },
            {
                coverFile: "junior_piano_3_cover.jpg",
                title: "Book 3",
                tracks: [
                    {
                        name: "Island Nights",
                        file: "JR/Island_Nights",
                        pdfFile: "JR/Junior_Piano_3_Ex1_Island_Nights"
                    },
                    {
                        name: "Jo's Dream",
                        file: "JR/Jo_s_Dream",
                        pdfFile: "JR/Junior_Piano_3_Ex2_Jo's_Dream"
                    },
                    {
                        name: "Nature's Lullaby",
                        file: "JR/Nature_s_Lullaby",
                        pdfFile: "JR/Junior_Piano_3_Ex3_Nature's_Lullaby"
                    }
                ]
            },
            {
                coverFile: "junior_piano_4_cover.jpg",
                title: "Book 4",
                tracks: [
                    {
                        name: "Galactic Force",
                        file: "JR/Galactic_Force",
                        pdfFile: "JR/Junior_Piano_4_Ex1_Galactic_Force"
                    },
                    {
                        name: "Let's Go Latin",
                        file: "JR/Let_s_Go_Latin",
                        pdfFile: "JR/Junior_Piano_4_Ex2_Let's_Go_Latin"
                    },
                    {
                        name: "Moonlight Waltz",
                        file: "JR/Moonlight_Waltz",
                        pdfFile: "JR/Junior_Piano_4_Ex3_Moonlight_Waltz",
                    }
                ]
            }
        ]
    },
    pr: {
        name: "primary piano",
        abrv: "PR",
        previews: [
            {
                coverFile: "primary_piano_1_cover.jpg",
                title: "Book 1",
                tracks: [
                    {
                        name: "Rock On D",
                        file: "PR/Rock_On_D",
                        pdfFile: "PR/Primary_Piano_1_Ex1_Rock_On_D"
                    },
                    {
                        name: "Grasshoppers' Feast",
                        file: "PR/Grasshoppers_Feast",
                        pdfFile: "PR/Primary_Piano_1_Ex2_Grasshoppers_Feast"
                    },
                    {
                        name: "Street Walk",
                        file: "PR/Street_Walk",
                        pdfFile: "PR/Primary_Piano_1_Ex3_Street_Walk"
                    }
                ]
            },
            {
                coverFile: "primary_piano_2_cover.jpg",
                title: "Book 2",
                tracks: [
                    {
                        name: "Dixie 21",
                        file: "PR/Dixie_21",
                        pdfFile: "PR/Primary_Piano_2_Ex1_Dixie_21"
                    },
                    {
                        name: "Jungle Cafe",
                        file: "PR/Jungle_Cafe",
                        pdfFile: "PR/Primary_Piano_2_Ex2_Jungle_Cafe"
                    },
                    {
                        name: "Bazaar",
                        file: "PR/Bazaar",
                        pdfFile: "PR/Primary_Piano_2_Ex3_Bazaar"
                    }
                ]
            }
        ]
    },
    acc: {
        name: "accent piano",
        abrv: "ACC",
        previews: [
            {
                coverFile: "accent_piano_1_cover.jpg",
                title: "Book 1",
                tracks: [
                    {
                        name: "Hitting The Big Time",
                        file: "ACC/Hitting_The_Big_Time",
                        pdfFile: "ACC/Accent_Piano_1_Ex1_Hitting_The_Big_Time"
                    },
                    {
                        name: "Groove",
                        file: "ACC/Groove",
                        pdfFile: "ACC/Accent_Piano_1_Ex2_Groove"
                    },
                    {
                        name: "Vintage Ride",
                        file: "ACC/Vintage_Ride",
                        pdfFile: "ACC/Accent_Piano_1_Ex3_Vintage_Ride"
                    }
                ]
            },
            {
                coverFile: "accent_piano_2_cover.jpg",
                title: "Book 2",
                tracks: [    
                    {
                        name: "Jazz Cafe",
                        file: "ACC/Jazz_Cafe",
                        pdfFile: "ACC/Accent_Piano_2_Ex1_Jazz_Cafe"
                    },
                    {
                        name: "Piano Sonata",
                        file: "ACC/Piano_Sonata",
                        pdfFile: "ACC/Accent_Piano_2_Ex2_Piano_Sonata"
                    },
                    {
                        name: "Rock It",
                        file: "ACC/Rock_It",
                        pdfFile: "ACC/Accent_Piano_2_Ex3_Rock_It"
                    }
                ]
            }
        ]
    },
    ach: {
        name: "achiever piano",
        abrv: "ACH",
        previews: [
            {
                coverFile: "achiever_1_cover.jpg",
                title: "Book 1: Preliminary",
                tracks: [
                    {
                        name: "From The Top",
                        file: "ACH/From_The_Top",
                        pdfFile: "ACH/Achiever_1_Ex1_From_The_Top"
                    },
                    {
                        name: "Chord Stomp",
                        file: "ACH/Chord_Stomp",
                        pdfFile: "ACH/Achiever_1_Ex2_Chord_Stomp"
                    },
                    {
                        name: "Chase It",
                        file: "ACH/Chase_It",
                        pdfFile: "ACH/Achiever_1_Ex3_Chase_It"
                    }
                ]
            },
            {
                coverFile: "achiever_2_cover.jpg",
                title: "Book 2: Grade One",
                tracks: [
                    {
                        name: "Arabesque",
                        file: "ACH/Arabesque",
                        pdfFile: "ACH/Achiever_2_Ex1_Arabesque"
                    },
                    {
                        name: "Yum Cha Cha",
                        file: "ACH/Yum_Cha_Cha",
                        pdfFile: "ACH/Achiever_2_Ex2_Yum_Cha_Cha"
                    },
                    {
                        name: "High Jinx",
                        file: "ACH/High_Jinx",
                        pdfFile: "ACH/Achiever_2_Ex3_High_Jinx"
                    }  
                ]
            },
            {
                coverFile: "achiever_3_cover.jpg",
                title: "Book 3: Grade Two",
                tracks: [
                    {
                        name: "Ten Thousand Miles",
                        file: "ACH/Ten_Thousand_Miles",
                        pdfFile: "ACH/Achiever_3_Ex1_Ten_Thousand_Miles"
                    },
                    {
                        name: "Ice Cool",
                        file: "ACH/Ice_Cool",
                        pdfFile: "ACH/Achiever_3_Ex2_Ice_Cool"
                    },
                    {
                        name: "Out Of Bounds",
                        file: "ACH/Out_Of_Bounds",
                        pdfFile: "ACH/Achiever_3_Ex3_Out_Of_Bounds"
                    }
                ]
            },
            {
                coverFile: "achiever_4_cover.jpg",
                title: "Book 4: Grades Three / Four",
                tracks: [
                    {
                        name: "The Harp",
                        file: "ACH/The_Harp",
                        pdfFile: "ACH/Achiever_4_Ex1_The_Harp"
                    },
                    {
                        name: "Legend of Tanis",
                        file: "ACH/Legend_Of_Tanis",
                        pdfFile: "ACH/Achiever_4_Ex2_Legend_Tanis",
                    },
                    {
                        name: "Kinabalu Sunrise",
                        file: "ACH/Kinabalu_Sunrise",
                        pdfFile: "ACH/Achiever_4_Ex3_Kinabalu_Sunrise"
                    },

                ]
            }
        ]
    },
    tw: {
        name: "theory wiz",
        abrv: "TW",
        noaudio: true,
        previews: [
            {
                coverFile: "Theory-wiz-1.jpg",
                title: "Book 1: Beginner",
                tracks: [
                    {
                        name: "Game Station",
                        pdfFile: "TW/THEORY_WIZ_1_Example_1_Game_Station"
                    },
                    {
                        name: "Rewind 1",
                        pdfFile: "TW/THEORY_WIZ_1_Example_2_Rewind_No1"
                    },
                    {
                        name: "Note Patterns",
                        pdfFile: "TW/THEORY_WIZ_1_Example_3_Note_Patterns"
                    }
                ]
            },
            {
                coverFile: "Theory-wiz-2.jpg",
                title: "Book 2: Preliminary",
                tracks: [
                    {
                        name: "Accidentals",
                        pdfFile: "TW/THEORY_WIZ_2_Example_1_Accidentals"
                    },
                    {
                        name: "Rewind 1",
                        pdfFile: "TW/THEORY_WIZ_2_Example_2_Rewind_No1"
                    },
                    {
                        name: "Grouping Quavers in 2/4",
                        pdfFile: "TW/THEORY_WIZ_2_Example_3_Grouping_Quavers_In_2_4"
                    }
                ]
            },
            {
                coverFile: "Theory-wiz-3.jpg",
                title: "Book 3: Grade One",
                tracks: [
                    {
                        name: "Rests and Rules",
                        pdfFile: "TW/THEORY_WIZ_3_Example_1_Rests_and_Rules"
                    },
                    {
                        name: "Major Scales",
                        pdfFile: "TW/THEORY_WIZ_3_Example_2_Major_Scales"
                    },
                    {
                        name: "Test Paper 1",
                        pdfFile: "TW/THEORY_WIZ_3_Example_3_Test_Paper_No1"
                    }
                ]
            }
        ]
    },
    st: {
        name: "encore on strings",
        abrv: "ST",
        previews: [
            {
                coverFile: "violin_1_cover.jpg",
                title: "Book 1",
                tracks: [
                    {
                        name: "Cosmic Cafe - Violin / Cello",
                        file: "ST/Cosmic_Cafe",
                        pdfFile: "ST/EOS_Maestros_BK1_Ex1_Cosmic_Cafe_VIOLIN_CELLO"
                    },
                    {
                        name: "Cosmic Cafe - Viola / Bass",
                        file: "ST/Cosmic_Cafe",
                        pdfFile: "ST/EOS_Maestros_BK1_Ex1_Cosmic_Cafe_VIOLA_BASS"
                    },
                    {
                        name: "Winners' Circle - Violin / Cello",
                        file: "ST/Winners_Circle",
                        pdfFile: "ST/EOS_Maestros_BK1_Ex2_Winners'_Circle_VIOLIN_CELLO"
                    },
                    {
                        name: "Winners' Circle - Viola / Bass",
                        file: "ST/Winners_Circle",
                        pdfFile: "ST/EOS_Maestros_BK1_Ex2_Winners'_Circle_VIOLA_BASS"
                    }
                ]
            },
            {
                coverFile: "violin_2_cover.jpg",
                title: "Book 2",
                tracks: [
                    {
                        name: "Get Down In G - Violin / Cello",
                        file: "ST/Get_Down_In_G",
                        pdfFile: "ST/EOS_Maestros_BK2_Ex1_Get_Down_In_G_VIOLIN_CELLO"
                    },
                    {
                        name: "Get Down In G - Viola / Bass",
                        file: "ST/Get_Down_In_G",
                        pdfFile: "ST/EOS_Maestros_BK2_Ex1_Get_Down_In_G_VIOLA_BASS"
                    },
                    {
                        name: "When The Saints - Violin / Cello",
                        file: "ST/When_The_Saints",
                        pdfFile: "ST/EOS_Maestros_BK2_Ex2_When_The_Saints_VIOLIN_CELLO"
                    },
                    {
                        name: "When The Saints - Viola / Bass",
                        file: "ST/When_The_Saints",
                        pdfFile: "ST/EOS_Maestros_BK2_Ex2_When_The_Saints_VIOLA_BASS"
                    }
                ]
            },
            {
                coverFile: "ensemble-book1.jpg",
                title: "Ensemble Book 1",
                tracks: [
                    {
                        name: "Beetles' Boots - Full Score",
                        pdfFile: "ST/EOS_Maestros_BK1_Ex1_Beetles'_Boots_Full_Score_sample"
                    },
                    {
                        name: "Beetles' Boots - Piano",
                        pdfFile: "ST/EOS_Maestros_BK1_Ex1_Beetles'_Boots_Piano_sample"
                    },
                    {
                        name: "Beetles' Boots - Violin 1",
                        pdfFile: "ST/EOS_Maestros_BK1_Ex1_Beetles'_Boots_Violin_1_sample"
                    },
                    {
                        name: "Beetles' Boots - Violin 2 / Viola / Cello / Bass",
                        pdfFile: "ST/EOS_Maestros_BK1_Ex1_Beetles'_Boots_Violin_2_Viola_Cello_Bass_sample"
                    }
                ]
            },
            {
                coverFile: "ensemble-book2.jpg",
                title: "Ensemble Book 2",
                tracks: [
                    {
                        name: "Fernando's Tango - Full Score",
                        pdfFile: "ST/EOS_Maestros_BK2_Ex1_Fernando's_Tango_Full_Score"
                    },
                    {
                        name: "Fernando's Tango - Piano",
                        pdfFile: "ST/EOS_Maestros_BK2_Ex1_Fernando's_Tango_Piano"
                    },
                    {
                        name: "Fernando's Tango - Violin 1 / Violin 2",
                        pdfFile: "ST/EOS_Maestros_BK2_Ex1_Fernando's_Tango_Violin_1_Violin_2"
                    },
                    {
                        name: "Fernando's Tango - Viola / Cello / Bass",
                        pdfFile: "ST/EOS_Maestros_BK2_Ex1_Fernando's_Tango_Viola_Cello_Bass"
                    }
                ]
            }
        ]
    },
    journal: {
        abrv: "Journal",
        name: "music journal",
        noaudio: true,
        previews: [
            {
                coverFile: "music-journal-cover.png",
                title: "Music Journal",
                tracks: [
                    {
                        name: "Lesson Notes",
                        pdfFile: "journal/Journal-samples-website-lessonnotes"
                    },
                    {
                        name: "My Pieces",
                        pdfFile: "journal/Journal-samples-website-mypieces"
                    },
                    {
                        name: "Circle of Fifths",
                        pdfFile: "journal/Journal-samples-website-circlefifths"
                    }
                ]
            }
        ]
    },
    tg: {
        noaudio: true,
        previews: [
            {
                coverFile: "KB1_TG.jpg",
                title: "Kinder Beat - Music Box Magic",
                tracks: [
                    {
                        name: "Music Time",
                        pdfFile: "TG/kinder_beat_tg_ex1_musictime"
                    },
                    {
                        name: "Kinder Bear Walks",
                        pdfFile: "TG/kinder_beat_tg_ex2_kinder_bear_walks"
                    },
                    {
                        name: "Waves Roll In",
                        pdfFile: "TG/kinder_beat_tg_ex3_waves_roll_in"
                    }
                ]
            },
            {
                coverFile: "J1_TG_Cover_2016-600px.png",
                title: "Junior Piano 1",
                tracks: [
                    {
                        name: "Hot Cross Buns",
                        pdfFile: "TG/Junior_1_TG_Ex1_Hot_Cross_Buns_sample"
                    },
                    {
                        name: "Cats In Coats",
                        pdfFile: "TG/Junior_1_TG_Ex2_Cats_In_Coats_sample"
                    },
                    {
                        name: "Jam With Jazz",
                        pdfFile: "TG/Junior_1_TG_Ex3_Jam_With_Jazz_sample"
                    }
                ]
            },
            {
                coverFile: "P1_TG_Cover_2016-600px.png",
                title: "Primary Piano 1",
                tracks: [
                    {
                        name: "Finger Tap 1",
                        pdfFile: "TG/Primary_1_TG_Ex1_Finger_Tap_1_sample"
                    },
                    {
                        name: "Exotic Market",
                        pdfFile: "TG/Primary_1_TG_Ex2_Exotic_Market_sample"
                    },
                    {
                        name: "Guess Who?",
                        pdfFile: "TG/Primary_1_TG_Ex3_Guess_Who_sample"
                    }
                ]
            }
        ]
    }
}